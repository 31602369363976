import { z } from 'zod';

import { CountrySchema } from '../domain/DomainLocation';

export const ExternalAgentType = {
  MOBILE_APP: 'mobileApp',
  CHAT_BOT: 'chatbot',
  LT_CHAT_BOT: 'ltChatbot',
  GARAGE_FORM: 'garageForm',
  HERTZ_ORDER_FORM: 'hertzOrderForm',
  REDGO_ORDER_FORM: 'redgoOrderForm',
  SPARX: 'sparx',
  EMAIL: 'email',
} as const;
export type ExternalAgentType =
  (typeof ExternalAgentType)[keyof typeof ExternalAgentType];

const ExternalAgentJobRequestBaseSchema = z.object({
  client: z.object({
    name: z.string(),
    phonenumber: z.string().optional(),
    country: CountrySchema.optional(),
    personCount: z.number().optional(),
    fdds: z.number().optional(),
  }),
  location: z.object({
    city: z.string(),
    address: z.string(),
    country: CountrySchema,
    description: z.string().optional(),
    coordinates: z
      .object({
        latitude: z.number(),
        longitude: z.number(),
      })
      .optional(),
  }),
  vehicle: z.object({
    registrationNumber: z.string(),
  }),
  situation: z.object({
    situationType: z.object({
      id: z.number(),
      description: z.string(),
    }),
    description: z.string().optional(),
  }),
  comments: z.string().optional(),
});

export const MobileAppRequestSchema = ExternalAgentJobRequestBaseSchema.extend({
  type: z.literal(ExternalAgentType.MOBILE_APP),
  client: ExternalAgentJobRequestBaseSchema.shape.client.extend({
    id: z.number(),
    homeTown: z.string().optional(),
    membershipValid: z.boolean().optional(),
    fdds: z.number().optional(),
  }),
  vehicle: ExternalAgentJobRequestBaseSchema.shape.vehicle.extend({
    mileage: z.coerce.string().optional(),
  }),
  situation: ExternalAgentJobRequestBaseSchema.shape.situation.extend({
    animalsOnBoard: z.boolean(),
    hasTrailer: z.boolean(),
    faultInTheTrailer: z.boolean(),
  }),
});
export type MobileAppRequest = z.infer<typeof MobileAppRequestSchema>;

export const AlChatBotRequestSchema = ExternalAgentJobRequestBaseSchema.extend({
  type: z.literal(ExternalAgentType.CHAT_BOT),
  client: ExternalAgentJobRequestBaseSchema.shape.client.extend({
    id: z.number(),
    homeTown: z.string().optional(),
    membershipValid: z.boolean().optional(),
  }),
  vehicle: ExternalAgentJobRequestBaseSchema.shape.vehicle.extend({
    mileage: z.coerce.string().optional(),
  }),
  situation: ExternalAgentJobRequestBaseSchema.shape.situation.extend({
    animalsOnBoard: z.boolean(),
    hasTrailer: z.boolean(),
    faultInTheTrailer: z.boolean(),
  }),
});
export type AlChatBotRequest = z.infer<typeof AlChatBotRequestSchema>;

export const LtChatBotRequestSchema = ExternalAgentJobRequestBaseSchema.extend({
  type: z.literal(ExternalAgentType.LT_CHAT_BOT),
});
export type LtChatBotRequest = z.infer<typeof LtChatBotRequestSchema>;

export const EmailCaseRequestSchema = ExternalAgentJobRequestBaseSchema.pick({
  client: true,
  location: true,
  vehicle: true,
}).extend({ type: z.literal(ExternalAgentType.EMAIL) });
export type EmailCaseRequest = z.infer<typeof EmailCaseRequestSchema>;

/* Request made by either mobileApp or chatbot */
export const ExternalAgentJobRequestSchema = z.discriminatedUnion('type', [
  AlChatBotRequestSchema,
  LtChatBotRequestSchema,
  MobileAppRequestSchema,
  EmailCaseRequestSchema,
]);
export type ExternalAgentJobRequest = z.infer<
  typeof ExternalAgentJobRequestSchema
>;

export const ExternalAgentCaseStatus = {
  AwaitingOperatorAction: 1,
  OpenedByOperator: 2,
  AwaitingAssistanceResponse: 3,
  AssistanceOnTheWay: 4,
  CancelRequestPending: 5,
  CancelRequestProcessing: 6,
  Cancelled: 7,
  Ready: 8,
} as const;
export type ExternalAgentCaseStatus =
  (typeof ExternalAgentCaseStatus)[keyof typeof ExternalAgentCaseStatus];

export const ExternalAgentCaseStatusText = {
  1: 'Awaiting operator action',
  2: 'Case opened by an operator',
  3: 'Awaiting assistance response',
  4: 'Assistance on the way',
  5: 'Cancel request pending, awaiting operator action',
  6: 'Case opened for cancelling by an operator',
  7: 'Case succesfully cancelled',
  8: 'Case completed successfully',
} as const;
export type ExternalAgentCaseStatusText =
  (typeof ExternalAgentCaseStatusText)[keyof typeof ExternalAgentCaseStatusText];

export type CaseStatuses = typeof ExternalAgentCaseStatusText;
export type CaseStatusText = ExternalAgentCaseStatusText;

export const ExternalAgentStatusResponseSchema = z.object({
  id: z.string(),
  status: z.nativeEnum(ExternalAgentCaseStatus),
  statusText: z.nativeEnum(ExternalAgentCaseStatusText),
});

export type ExternalAgentStatusResponse = z.infer<
  typeof ExternalAgentStatusResponseSchema
>;

export const AddressSchema = z.object({
  address: z.string(),
  city: z.string(),
  zipCode: z.string(),
  country: z.string(),
});

export const BaseInvoicingSchema = z.object({
  jobId: z.string().optional(),
  invoiceId: z.string().optional(),
  relayedDate: z.string().optional(),
  serviceProviderName: z.string().optional(),
  sum: z.number().optional(),
  arrivedDate: z.string().optional(),
  repatriation: z.boolean(),
});

export const RsaOrTowServiceInvoicingSchema = BaseInvoicingSchema.extend({
  serviceType: z.literal('rsaOrTrailer'),
  serviceDescription: z.string().optional(),
  serviceProviderAddress: AddressSchema.optional(),
  readyDate: z.string(),
  distance: z.number().optional(),
  towedDistance: z.number().optional(),
  trailerInsistedByCustomer: z.boolean(),
  invoicedFromOtherParty: z.boolean(),
});

export const ReplacementServiceInvoicingSchema = BaseInvoicingSchema.extend({
  serviceType: z.literal('replacementCar'),
  readyDate: z.string().optional(),
  location: z.string().optional(),
  pickUpLocation: AddressSchema.optional(),
  dropOffLocation: AddressSchema.optional(),
  days: z.number().optional(),
  vehicleTypeCode: z.string().optional(),
  vehicleMark: z.string().optional(),
  vehicleModel: z.string().optional(),
  additionalMessage: z.string().optional(),
});

// these are ARC drive train types
export const DriveTrain = {
  FRONT_WHEEL: 'FrontWheelDrive',
  REAR_WHEEL: 'RearWheelDrive',
  FOUR_WHEEL: 'FourWheelDrive',
  // ALL_WHEEL: 'AllWheelDrive',
} as const;
export type DriveTrain = (typeof DriveTrain)[keyof typeof DriveTrain];

export const EmailCaseCreateEvent = 'Email.TaskCard.Create' as const;
